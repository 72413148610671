import { PageProps, graphql } from 'gatsby';
import { ContentfulTemplatePage } from 'graphqlTypes';
import Layout from 'layouts/Layout';
import LayoutSection from 'layouts/LayoutSection';
import { FC } from 'react';

interface PageDataType {
  pageData: ContentfulTemplatePage;
}

const BlogListingsInCategory: FC<PageProps<PageDataType>> = ({
  data: {
    pageData: { seo, sections },
  },
  location,
}) => {
  if (!seo) {
    throw new Error(`Page at ${location.pathname} is missing SEO`);
  }

  return (
    <Layout slug={location.pathname} seo={seo}>
      {sections?.map(section => section && <LayoutSection key={section.id} {...section} />)}
    </Layout>
  );
};

export default BlogListingsInCategory;

export const blogCategoryQuery = graphql`
  query newsListingsCategoryQuery {
    pageData: contentfulTemplatePage(slug: { eq: "company/news" }) {
      ...contentfulTemplatePage
    }
  }
`;
